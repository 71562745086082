import React from "react";
import MultiSlider from "./multiSlider";
import Navbar from "./navbar";
// import ImageSlider from "./imageSlider";

const TopView = () => {
  return (
    <div className="top-view">
      <Navbar />
      <br />
      <MultiSlider />
    </div>
  );
};

export default TopView;
