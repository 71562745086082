export let reviewData = [
  {
    Name: "Marina Trigonis",
    Review:
      "“Antonio was VERY professional! He built 2 pairs of outdoor shutters for my home. He stained them and installed them. They are BEAUTIFUL! His quote was extremely reasonable and his workmanship was amazing. From start to finish it was 2 days. I will be using his handyman services again. I 100% recommend him!”",
  },
  {
    Name: "Brigitte Munoz",
    Review:
      "“Antonio was very professional and very responsive. I had originally hired him to-re do the flooring at my moms house but we ended up wanting to paint and to do a small change within the home, we also had a pony wall that was of no use and asked to have it removed. Antonio was able to remove that wall and even out the edge which made it look like the pony wall never existed. We also needed the tv hung in a different spot and he was able to remove it, fix the old holes and hang it in the new spot. Antonio was very flexible with the additional things we wanted to get done. He has very fair and reasonable prices and did an outstanding job with everything we asked for. We are definitely going to hire him again for future jobs that we need and I would highly recommend him to anyone that needs house work. 10/10 job! Thank you Antonio!”",
  },
  {
    Name: "Jose Alvinez",
    Review:
      "“I hired Antonio to come by and replace my ceiling fan and fix some drywall. He was prompt on answering my phone call and scheduled a walk through. His price was very reasonable, and was scheduled within a week after! I would highly recommend him and hire him again!!!”",
  },
  {
    Name: "Kevin O'Neill",
    Review:
      "“Antonio replaced a side gate that had termite damage.  He was responsive, quick and professional and I'm very satisfied with his work.  I would enthusiastically recommend him and will definitely use him for future projects.”",
  },
  {
    Name: "Jacob Angelo",
    Review:
      "“Great quality, Great communication, very reasonable cost. Antonio rehung and painted 4 interior doors, he was very professional, had his own tools and worked quickly. We definitely will be calling him for all our handyman needs in the future.”",
  },
  {
    Name: "Taylor Freels",
    Review:
      "“I don't typically leave reviews but these guys outshined what I could have ever imagined my bathroom remodel could look like. I called several contractors for pricing and asked for pictures of past work. Just like anything else, pictures don't do it justice! Future work that I'll need done will only be done by grade A! Thank you guys so much”",
  },
  {
    Name: "Kristine Murray",
    Review:
      "“Anthony did an excellent job installing insulation in our garage.  Would recommend his services to all and will be using him for all our handyman needs. Thanks Anthony!”",
  },
  {
    Name: "Rich Sattro",
    Review:
      "“Antonio is a very cool guy and knows his business; definitely has a “can do” attitude and was on time and prepared for the job. I will definitely be using his services again and I would recommend him to anyone.”",
  },
  {
    Name: "Jessica Montez",
    Review:
      "“I’m so happy with the work that Antonio and his team did at our house. They take pride in what they do and it definitely shows in their work. Great quality at a reasonable price. I would highly recommend Grade A Handyman Services!”",
  },
  {
    Name: "Aimee Flores",
    Review:
      "“I've received quality work from Grade A Handyman Services;  Antonio and his partner Vincent assured I was satisfied with their work by creating  clear channels of communication with me. They assembled my desk,  stained and mounted shelves, and hung my TV. Thanks again! (:”",
  },
  {
    Name: "Omar Escobar",
    Review:
      "“The service was excellent and very professional. Replaced the toilet and sink faucets and looks great! Would definitely recommend to use them and will be using again for future repairs.”",
  },
  {
    Name: "Kimberly Butscher",
    Review: "“Excellent service - highly recommend. Very helpful!”",
  },
  {
    Name: "Kathleen Murray",
    Review:
      "“Great customer service and quality workmanship. I highly recommend this company.”",
  },
  {
    Name: "Manohar Vardineni",
    Review: "“Good Quality service and hassle free.”",
  },
];

export let servicesDetails = [
  {
    Name: "ASSEMBLY",
    beforePic: "Images/Services/assembly/before.jpg",
    afterPic: "Images/Services/assembly/after.jpg",
    list: [
      "FURNITURE ASSEMBLY",
      "BED FRAMES",
      "DESK ",
      "WORKOUT MACHINES",
      "STORAGE RACKS",
      "BIKES",
      "TABLES",
      "SHELVING UNITS",
      "PERGOLA",
      "GROUNDPOOLS",
    ],
    details:
      "The instructions for constructing a new piece of furniture can be complex, and the assembly process itself can be time-consuming and sometimes extremely difficult. Grade A Handyman Services is ready to make tasks hassle for you right away!.",
  },
  {
    Name: "REPAIR",
    beforePic: "Images/Services/repair/before.jpg",
    afterPic: "Images/Services/repair/after.jpg",
    list: [
      "BATHROOM REPAIR",
      "CIELING FAN REPAIR",
      "DECK PATIO REPAIR",
      "DOOR REPAIR",
      "DRYWALL REPAIR",
      "FLOORING REPAIR",
      "GARAGE DOOR REPAIR",
      "GROUT REPAIR",
      "GUTTER REPAIR",
      "PLUMBING REPAIR",
      "SHOWER HEAD REPAIR",
      "SINK REPAIR",
      "STEPS REPAIR",
    ],
    details: "When stuff breaks, we fix it. We can save you time and money.",
  },
  {
    Name: "CARPENTRY",
    beforePic: "Images/Services/carpentry/before.jpg",
    afterPic: "Images/Services/carpentry/after.jpg",
    list: ["CUSTOM SHELVING", "CROWN MOLDING", "CUSTOM CABINETS AND MANTALES"],
    details:
      "We install and build fireplace mantels, cabinets, bookcases, wooden countertops and more.",
  },
  {
    Name: "DRYWALL",
    beforePic: "Images/Services/drywall/before.jpg",
    afterPic: "Images/Services/drywall/after.jpg",
    list: [
      "Drywall mudding",
      "Drywall sanding",
      "Drywall seam/joint taping",
      "Cracks from settling",
      "Damage from moisture",
      "Regular wear and tear",
      "Damage from kids",
      "Damage from mice",
      "Holes from drywall anchors",
      "Door knob holes",
      "Throwing darts holes",
    ],
    details:
      "We specialize in drywall installation and finishing for remodels, kitchens, bathrooms, and additions in both the residential and commercial sectors. For electrical/plumbing or demolition work, we also do drywall repair. Additionally, drywall crack and water damage repairs, as well as light demo and masking protection, are available. We ensure that each drywall sheet is installed to the highest possible standard. We are proud of our exceptional customer service and affordable costs.",
  },
  {
    Name: "PAINTING",
    beforePic: "Images/Services/painting/before.jpg",
    afterPic: "Images/Services/painting/after.jpg",
    list: [
      "Crown molding and trim painting",
      "Door painting",
      "Deck staining, painting and finishing",
      "Furniture painting and staining",
    ],
    details:
      "A fresh coat of paint can breathe life into any room, exterior or piece of furniture.and it's our mission to deliver the level of detail and professionalism that you expect and deserve.",
  },
  {
    Name: "INSTALLATION",
    beforePic: "Images/Services/installation/before.jpg",
    afterPic: "Images/Services/installation/after.jpg",
    list: [
      "TV Install",
      "Window Install",
      "Basketball Hoop",
      "Handrails and Stairs",
      "Gutter Guard",
      "Faucet and Shower Head Install",
      "Toilet Installation",
      "Baby Proofing",
      "SHIPLAP",
      "WALLPAPER",
      "BARNDOOR",
    ],
    details:
      "Whatever you need installed in your home, our experienced and insured handyman will get the job done right and on time",
  },
  {
    Name: "REMODELING",
    beforePic: "Images/Services/remodelling/before.jpg",
    afterPic: "Images/Services/remodelling/after.PNG",
    list: [
      "BASEMENT REMODEL",
      "ROOM REMODEL",
      "BATHROOM REMODEL",
      "KITCHEN REMODEL",
      "LIVING ROOM REMODEL",
    ],
    details:
      "Our remodeling professionals have an average of 10 years' experience, which means there's practically no job they can't handle. If you don't see your specific room or service listed, call to speak to us about your remodeling project. ",
  },
  {
    Name: "MAINTENANCE",
    beforePic: "Images/Services/maintainance/before.jpg",
    afterPic: "Images/Services/maintainance/after.jpg",
    list: [
      "POWER WASH SERVICE",
      "DRY VENT CLEANING",
      "ATTIC MAINTENANCE",
      "BATHROOM MAINTENANCE",
      "GARAGE MAINTENANCE",
      "Deck Maintenance",
      "Fence Maintenance",
      "Storm Door Installation",
      "Exterior Stucco Repair",
      "Gutter Maintenance",
    ],
    details:
      "The little things add up. That's why regular maintenance in and around the home is especially important.",
  },
  {
    Name: "SAFETY & MOBILITY",
    beforePic: "",
    afterPic: "",
    list: [
      "Installation of guardrails and handrails",
      "Assembly of more-suitable furniture",
      "Widening of doorways",
      "Raising of toilet seats",
      "Installation of grab bars",
      "Installation of brand-new showerheads",
      "Enhancing the lighting fixtures",
      "The upgrading of cabinet and door handles",
      "The moving of light switches and electrical outlets",
    ],
    details:
      "We can come to your home and perform various tasks and modifications that make it possible for you to stay comfortably in your home through your golden years.",
  },
  {
    Name: "PLUMBING",
    beforePic: "Images/Services/plumbing/before.jpg",
    // afterPic: "Images/Services/plumbing/after.jpg",
    afterPic: "",
    list: [
      "Faucet change",
      "Water heater repair & installation",
      "Faucet",
      "Set Sink",
      "Replace Water Valve Stops",
      "Garbage Disposals",
      "New Toilet install",
      "Install replace Toilets",
      "Caulk Tubs and Sinks",
      "Repair / Replace Hose Bib",
      "Copper Pipe Repair",
      "Install Dishwasher",
      "Install Shower Cartridge",
    ],
    details:
      "We are reliable for any plumbing task, regardless of its nature and severity. We guarantee to deliver competent service whether you need repair work, maintenance, or installation of the entire sewerage line. Over the years, we have successfully served countless commercial and residential clients. Your satisfaction is our ultimate reward!",
  },
];
