import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";

const Footer2 = () => {
  return (
    <div>
      <div>
        <div className="container-fluid justify-content-center text-light main-foorter">
          <footer>
            <div className="row my-3 justify-content-center pt-3">
              <div className="col-11">
                <div className="row ">
                  <div className="col-xl-4 my-auto mx-auto">
                    <h3 className="mb-md-0 mb-5 text-muted">
                      Grade A Handyman Services Inc.
                    </h3>
                    <p>
                      &nbsp;&nbsp;&nbsp;&nbsp; We are social, looking forward to
                      work with you
                    </p>
                    <br />
                  </div>
                  <div className="col-xl-2">
                    <h6 className="mb-3 text-muted ">
                      <b>Services</b>
                    </h6>
                    <ul className="list-unstyled">
                      <li>
                        <Link
                          className="links-footer text-light"
                          to="/ser"
                          state={{ index: 0 }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          Assembly
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="links-footer text-light"
                          to="/ser"
                          state={{ index: 1 }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          Repair
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="links-footer text-light"
                          to="/ser"
                          state={{ index: 2 }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          Carpentry
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="links-footer text-light"
                          to="/ser"
                          state={{ index: 3 }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          Drywall
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="links-footer text-light"
                          to="/ser"
                          state={{ index: 5 }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          Installation
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xl-2">
                    <h6 className="mb-3 text-muted">
                      <b>Services</b>
                    </h6>
                    <ul className="list-unstyled">
                      <li>
                        <Link
                          className="links-footer text-light"
                          to="/ser"
                          state={{ index: 8 }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          Safety &amp; Mobility
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="links-footer text-light"
                          to="/ser"
                          state={{ index: 6 }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          Remodeling
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="links-footer text-light"
                          to="/ser"
                          state={{ index: 4 }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          Painting
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="links-footer text-light"
                          to="/ser"
                          state={{ index: 7 }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          Maintainance
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="links-footer text-light"
                          to="/ser"
                          state={{ index: 9 }}
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          Plumbing
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xl-2 pb-3">
                    <h6 className="mb-3 text-muted">
                      <b>MENU</b>
                    </h6>
                    <ul className="list-unstyled">
                      <li>
                        <Link
                          className="links-footer text-light"
                          to="/"
                          onClick={() => {
                            window.scrollTo(0, 0);
                          }}
                        >
                          Home
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="links-footer text-light"
                          to="/"
                          onClick={() => {
                            const anchor = document.querySelector("#about");
                            anchor.scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                          }}
                        >
                          About
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="links-footer text-light"
                          to="/"
                          onClick={() => {
                            // if (!props.service) {
                            const anchor = document.querySelector("#services");
                            anchor.scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                            // }
                          }}
                        >
                          Servives
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="links-footer text-light"
                          to="/"
                          onClick={() => {
                            // if (!props.review) {
                            const anchor = document.querySelector("#review");
                            anchor.scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                            // }
                          }}
                        >
                          Testimonials
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="links-footer text-light"
                          to="/"
                          onClick={() => {
                            const anchor = document.querySelector("#contact");
                            anchor.scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                          }}
                        >
                          Contact
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col-xl-2">
                    <h6 className="mb-3 text-muted mt-sm-0 mt-5">
                      <b>ADDRESS</b>
                    </h6>
                    <p>
                      14347 Old Community Rd, Street,
                      <br />
                      Poway, CA 92064,
                      <br />
                      United States
                    </p>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-xl-4 mt-5 order-sm-1">
                    <p className="text-muted mb-0 pb-0">
                      <span className="mx-2">
                        <Link
                          to="\instagram.com/grade_a_handyman?igshid=YmMyMTA2M2Y="
                          target="_blank"
                        >
                          <FontAwesomeIcon
                            icon={faInstagram}
                            size="2x"
                            style={{ color: "#fff" }}
                          />
                        </Link>
                      </span>

                      <span className="mx-2">
                        <Link to="\g.co/kgs/YM9ncn" target="_blank">
                          <FontAwesomeIcon
                            icon={faGoogle}
                            size="2x"
                            style={{ color: "#fff" }}
                          />
                        </Link>
                      </span>
                      <span className="mx-2">
                        <a
                          className="nav-mail"
                          href="mailto:a.z.handyworkservice@gmail.com"
                        >
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            size="2x"
                            style={{ color: "#fff" }}
                          />
                        </a>
                      </span>
                      <span className="mx-2">
                        <a href="tel:8583827226">
                          <FontAwesomeIcon
                            icon={faPhone}
                            size="2x"
                            style={{ color: "#fff" }}
                          />
                        </a>
                      </span>
                      <span className="mx-2">
                        <Link
                          to="\yelp.com/biz/grade-a-handyman-services-poway"
                          target="_blank"
                        >
                          <img
                            src="Images/yelp.png"
                            alt="yelp"
                            className="footer-img-yelp"
                          />
                        </Link>
                      </span>
                      <span className="mx-2">
                        <Link
                          to="\angi.com/companylist/us/ca/poway/grade-a-handyman-services-reviews-8679863.htm"
                          target="_blank"
                        >
                          <img
                            src="Images/Angi.svg"
                            alt="angi"
                            className="footer-img"
                          />
                        </Link>
                      </span>
                    </p>
                    <small className="rights">
                      <span>&#174;</span> Grade A Handyman. All Rights Reserved.
                    </small>
                  </div>
                  <div className="col-xl-2 order-1 align-self-end mt-5">
                    <h6 className="text-muted">
                      <b>Contact Us</b>
                    </h6>
                    <small>
                      <span>
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </span>
                      <p>
                        (858) 382-7226 <br /> (760) 297-0886 <br />
                        <br />
                        a.z.handyworkservice@gmail.com
                      </p>
                    </small>
                    <br />
                    <br />
                    <br />
                  </div>
                  <div className="col-xl-2 order-1 align-self-end mt-5">
                    <h6 className="text-muted">
                      <b>CEO Grade A Handyman Services Inc</b>
                    </h6>
                    <small>
                      <span>
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                      </span>
                      <p>
                        David Antonio Zacarias
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                      </p>
                    </small>
                  </div>
                  <div className="col-xl-4 order-sm-1 mt-5">
                    <div className="mapouter">
                      <div className="gmap_canvas">
                        <iframe
                          title="Map Location"
                          width="100%"
                          height="200"
                          id="gmap_canvas"
                          src="https://maps.google.com/maps?q=14347%20Old%20Community%20Rd,%20Street,%20Poway,%20CA%2092064,%20United%20States&t=&z=13&ie=UTF8&iwloc=&output=embed"
                          frameborder="0"
                          scrolling="no"
                          marginheight="0"
                          marginwidth="0"
                        ></iframe>
                        {/* <a href="https://123movies-to.org"></a> */}
                        <br />
                        <style>
                          .mapouter
                          {
                            "position:relative;text-align:right;height:200px;width:300px;"
                          }
                        </style>
                        {/* <a href="https://www.embedgooglemap.net"></a> */}
                        <style>
                          .gmap_canvas
                          "overflow:hidden;background:none!important;height:200px;width:300px;"
                        </style>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </div>
  );
};

export default Footer2;
