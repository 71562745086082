import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsToDot,
  faCompassDrafting,
  faDungeon,
  faFaucet,
  faPaintRoller,
  faPeopleRoof,
  faScrewdriverWrench,
  faSuitcase,
  faTableColumns,
  faWrench,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const Services = (props) => {
  return (
    <div id="services">
      <h1 className="display-4 text-center">{props.header}</h1>
      <div className="container">
        <div className="services">
          <Link
            to="/ser"
            state={{ index: 0 }}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="btn btn-outline-primary service-button">
              <FontAwesomeIcon icon={faArrowsToDot} size="3x" />
              <br />
              <br />
              Assembly
            </button>
          </Link>
          <Link
            to="/ser"
            state={{ index: 1 }}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="btn btn-outline-dark service-button">
              <FontAwesomeIcon icon={faScrewdriverWrench} size="3x" />
              <br />
              <br />
              Repair
            </button>
          </Link>
          <Link
            to="/ser"
            state={{ index: 2 }}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="btn btn-outline-success service-button">
              <FontAwesomeIcon icon={faDungeon} size="3x" />
              <br />
              <br />
              Carpentry
            </button>
          </Link>
          <Link
            to="/ser"
            state={{ index: 3 }}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="btn btn-outline-danger service-button">
              <FontAwesomeIcon icon={faTableColumns} size="3x" />
              <br />
              <br />
              Drywall
            </button>
          </Link>
          <Link
            to="/ser"
            state={{ index: 5 }}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="btn btn-outline-info service-button">
              <FontAwesomeIcon icon={faSuitcase} size="3x" />
              <br />
              <br />
              Installation
            </button>
          </Link>
          <Link
            to="/ser"
            state={{ index: 8 }}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="btn btn-outline-danger service-button">
              <FontAwesomeIcon icon={faPeopleRoof} size="3x" />
              <br />
              <br />
              Safety and Mobility
            </button>
          </Link>
          <Link
            to="/ser"
            state={{ index: 6 }}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="btn btn-outline-secondary service-button">
              <FontAwesomeIcon icon={faCompassDrafting} size="3x" />
              <br />
              <br />
              Remodeling
            </button>
          </Link>
          <Link
            to="/ser"
            state={{ index: 4 }}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="btn btn-outline-primary service-button">
              <FontAwesomeIcon icon={faPaintRoller} size="3x" />
              <br />
              <br />
              Painting
            </button>
          </Link>
          <Link
            to="/ser"
            state={{ index: 7 }}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="btn btn-outline-dark service-button">
              <FontAwesomeIcon icon={faWrench} size="3x" />
              <br />
              <br />
              Maintainance
            </button>
          </Link>
          <Link
            to="/ser"
            state={{ index: 9 }}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="btn btn-outline-success service-button">
              <FontAwesomeIcon icon={faFaucet} size="3x" />
              <br />
              <br />
              Plumbing
            </button>
          </Link>
        </div>
        <br />
      </div>
    </div>
  );
};

export default Services;
