import React from "react";
import AboutUs from "./about";
import Services from "./services";
import Welcome from "./welcome";
import TopView from "./topView";
// import Footer from "./footer";
import Review from "./review";
import ContactUs from "./contact";
import Footer2 from "./footer2";
import ServiceArea from "../serviceArea";

const LandingPage = () => {
  return (
    <div className="greybg">
      <TopView />
      <Welcome />
      <Services header="Our Services" />
      <AboutUs />
      <Review />
      <ContactUs />
      <ServiceArea />
      {/* <Footer /> */}
      {/* <br /> */}
      <Footer2 />
    </div>
  );
};

export default LandingPage;
