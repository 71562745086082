import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { reviewData } from "../Constants";
import { Link } from "react-router-dom";
const Review = () => {
  return (
    <div className=" my-5" id="review">
      <div className="container review-page">
        <h2 className="display-4 text-center text-light">Customer Reviews</h2>
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner text-center">
            <div className="carousel-item active">
              <div className="my-3">
                <FontAwesomeIcon
                  className="mx-1"
                  icon={faStar}
                  // size="2x"
                  color="yellow"
                />
                <FontAwesomeIcon
                  className="mx-1"
                  icon={faStar}
                  // size="2x"
                  color="yellow"
                />
                <FontAwesomeIcon
                  className="mx-1"
                  icon={faStar}
                  // size="2x"
                  color="yellow"
                />
                <FontAwesomeIcon
                  className="mx-1"
                  icon={faStar}
                  // size="2x"
                  color="yellow"
                />
                <FontAwesomeIcon
                  className="mx-1"
                  icon={faStar}
                  // size="2x"
                  color="yellow"
                />
              </div>
              <div className="text-light review-text">
                “Antonio was VERY professional! He built 2 pairs of outdoor
                shutters for my home. He stained them and installed them. They
                are BEAUTIFUL! His quote was extremely reasonable and his
                workmanship was amazing. From start to finish it was 2 days. I
                will be using his handyman services again. I 100% recommend
                him!”
              </div>
              <p className="text-light">
                <strong>- Marina Trigonis</strong>
              </p>
            </div>
            {reviewData.slice(2, 6).map((item, i) => (
              <div className="carousel-item" key={i}>
                <div className="my-3">
                  <FontAwesomeIcon
                    className="mx-1"
                    icon={faStar}
                    // size="2x"
                    color="yellow"
                  />
                  <FontAwesomeIcon
                    className="mx-1"
                    icon={faStar}
                    // size="2x"
                    color="yellow"
                  />
                  <FontAwesomeIcon
                    className="mx-1"
                    icon={faStar}
                    // size="2x"
                    color="yellow"
                  />
                  <FontAwesomeIcon
                    className="mx-1"
                    icon={faStar}
                    // size="2x"
                    color="yellow"
                  />
                  <FontAwesomeIcon
                    className="mx-1"
                    icon={faStar}
                    // size="2x"
                    color="yellow"
                  />
                </div>
                <div className="text-light review-text">{item.Review}</div>
                <p className="text-light">
                  <strong>- {item.Name}</strong>
                </p>
              </div>
            ))}
          </div>
          <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
        <div className="text-center">
          <Link
            to="/reviews"
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <button className="btn btn-outline-warning button-yellow">
              More Reviews
            </button>
          </Link>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default Review;
