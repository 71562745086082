import React from "react";

const AboutUs = () => {
  return (
    <div>
      <div id="about" className="container1-fluid about1">
        <div className="row">
          <div className="col-lg-8">
            <h2 className="display-4 text-center greybg curveborder">
              About Company
            </h2>
            <br />
            <h4 className="greybg text-center curveborder">
              Since 2008, Antonio has worked in the industry. In North County
              San Diego, he is a well-known veteran and reliable handyman.
              Antonio and his team are devoted professionals that work together
              based on the family/small business values.
            </h4>
            <br />
            <h2 className="display-5 text-center greybg curveborder">
              Business Highlights
            </h2>
            <div className="business-high greybg curveborder">
              <div className="highlight-item text-center">
                <img src="Images/veteran.png" alt="21" />
                <h5>Veteran Owned &amp; Operated</h5>
              </div>
              <div className="highlight-item text-center">
                <img src="Images/lgbtq.png" alt="21" />
                <h5>LGBTQ+ Friendly</h5>
              </div>
              <div className="highlight-item text-center">
                <img src="Images/family.png" alt="21" />
                <h5>Family Owned &amp; Operated</h5>
              </div>
              <div className="highlight-item text-center">
                <img src="Images/esp.png" alt="21" />
                <h5>Se Habla Español</h5>
              </div>
              <div className="highlight-item text-center">
                <img src="Images/247.png" alt="21" />
                <h5>24/7 Service</h5>
              </div>
            </div>
            <br />
          </div>
          <div className="col-lg-4">
            <img className="about-img" src="Images/president.png" alt="21" />
          </div>
        </div>
      </div>

      <div className="container1-fluid about2">
        <div className="row">
          <div className="col-lg-4">
            <img className="about-img" src="Images/about2.jpg" alt="21" />
          </div>
          <div className="col-lg-8 pt-5 mt-5">
            <h2 className="greybg curveborder">Our Values</h2>
            <br />
            {/* <h4 className="bg-light"> */}
            <h4 className="greybg text-center curveborder">
              <strong>MISSION:</strong> We will respond promptly to your
              request. We’ll listen to you with courtesy. We’ll book your
              service promptly upon approval of the estimate. At Grade A
              Handyman Services Inc we believe in exceptional service and
              quality workmanship which is why we back every job we do with our
              worry-free guarantee.
            </h4>
            <br />
            {/* <p className="bg-light"> */}
            <h4 className="text-center greybg curveborder">
              What To Expect When You Work With Us!
            </h4>
            <p className="greybg text-center curveborder">
              Choose us if you are looking for consistent, high-quality services
              that you can rely on and don’t want to be charged a hefty amount
              for. <strong>Save Time</strong>,<strong> Save Money</strong>,
              <strong> No Stress</strong>, <strong>Honest Answers</strong>, and
              <strong> Affordable Pricing</strong>.
            </p>
            <ul className="pt-2 greybg curveborder">
              {/* <li>
                <strong>Licensed &amp; Insured Handymen (#0D40566)</strong>
              </li> */}
              <li>
                <strong>
                  Full-Service Improvement (Commercial and Residential)
                </strong>
              </li>
              <li>
                <strong>Affordable Pricing &amp; Guaranteed Results</strong>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
