import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelopeCircleCheck,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
// import { Link } from "react-router-dom";

const Welcome = () => {
  return (
    <div className="main-welcome-window">
      <div className="text-center">
        <img
          className="img-fluid welcomelogo"
          src="/Images/img0.png"
          alt="Add logo"
        />
        {/* <p>
          <strong>US Marines Award</strong>
        </p> */}
      </div>
      <div>
        <div className="welcomedatacontainer greybg">
          <div className="welcomedata">
            <a href="tel:8583827226">
              <FontAwesomeIcon
                icon={faPhone}
                size="2x"
                color="#273f67"
                beat="true"
              />
            </a>
            &nbsp;
            <h2>(858) 382-7226</h2>
          </div>
          <div className="welcomedata">
            <a href="mailto:a.z.handyworkservice@gmail.com">
              <FontAwesomeIcon
                icon={faEnvelopeCircleCheck}
                size="2x"
                color="#273f67"
                beat="true"
              />
            </a>
            &nbsp;
            <h2 className="email-welcome">a.z.handyworkservice@gmail.com</h2>
          </div>
        </div>
        <p className="text-center greybg welcome-text px-5">
          Contact to schedule a consultation. Please include photos and detailed
          explanations of the project. The price range is determined by the
          complexity of the duties, which might range from minor to major, and
          from unskilled to highly competent.
        </p>
      </div>
      <div className="imgcontainer bg-light">
        <img
          className="img-fluid imgwelcome"
          src="/Images/img1.png"
          alt="Award 1"
        />
        <img
          className="img-fluid imgwelcome"
          src="/Images/img2.png"
          alt="Award 2"
        />
        <img
          className="img-fluid imgwelcome"
          src="/Images/img3.png"
          alt="Award 3"
        />
        <img
          className="img-fluid imgwelcome"
          src="/Images/img4.png"
          alt="Award 4"
        />
        <img
          className="img-fluid imgwelcome"
          src="/Images/img5.png"
          alt="Award 5"
        />
        <img
          className="img-fluid imgwelcome"
          src="/Images/img6.png"
          alt="Award 6"
        />
      </div>
      <br />
    </div>
  );
};

export default Welcome;
