import React from "react";
import Carousel from "react-multi-carousel";

const MultiSlider = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
      // partialVisibilityGutter: 40
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
      // partialVisibilityGutter: 30
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      // partialVisibilityGutter: 30
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      // partialVisibilityGutter: 30
    },
  };
  return (
    <div>
      <Carousel
        responsive={responsive}
        // showDots={true}
        centerMode={true}
        // slidesToSlide={2}
        // transitionDuration={500}
      >
        <img
          className="sliderimg"
          src="Images/Slider/2.jpg"
          alt="1"
          onMouseOver={(e) => (e.currentTarget.src = "Images/Slider/22.jpg")}
          onMouseOut={(e) => (e.currentTarget.src = "Images/Slider/2.jpg")}
        />
        <img
          className="sliderimg"
          src="Images/Slider/01.jpg"
          alt="2"
          onMouseOver={(e) => (e.currentTarget.src = "Images/Slider/110.jpg")}
          onMouseOut={(e) => (e.currentTarget.src = "Images/Slider/01.jpg")}
        />
        <img
          className="sliderimg"
          src="Images/Slider/3.jpg"
          alt="3"
          onMouseOver={(e) => (e.currentTarget.src = "Images/Slider/330.jpg")}
          onMouseOut={(e) => (e.currentTarget.src = "Images/Slider/3.jpg")}
        />
        <img
          className="sliderimg"
          src="Images/Slider/4.jpg"
          alt="4"
          onMouseOver={(e) => (e.currentTarget.src = "Images/Slider/44.jpg")}
          onMouseOut={(e) => (e.currentTarget.src = "Images/Slider/4.jpg")}
        />
        <img
          className="sliderimg"
          src="Images/Slider/5.jpg"
          alt="5"
          onMouseOver={(e) => (e.currentTarget.src = "Images/Slider/55.jpg")}
          onMouseOut={(e) => (e.currentTarget.src = "Images/Slider/5.jpg")}
        />
        <img
          className="sliderimg"
          src="Images/Slider/6.jpg"
          alt="6"
          onMouseOver={(e) => (e.currentTarget.src = "Images/Slider/66.jpg")}
          onMouseOut={(e) => (e.currentTarget.src = "Images/Slider/6.jpg")}
        />
        <img
          className="sliderimg"
          src="Images/Slider/770.jpg"
          alt="7"
          onMouseOver={(e) => (e.currentTarget.src = "Images/Slider/77.jpg")}
          onMouseOut={(e) => (e.currentTarget.src = "Images/Slider/770.jpg")}
        />
        <img
          className="sliderimg"
          src="Images/Slider/8.jpg"
          alt="8"
          onMouseOver={(e) => (e.currentTarget.src = "Images/Slider/88.jpg")}
          onMouseOut={(e) => (e.currentTarget.src = "Images/Slider/8.jpg")}
        />
        <img
          className="sliderimg"
          src="Images/Slider/9.jpg"
          alt="9"
          onMouseOver={(e) => (e.currentTarget.src = "Images/Slider/99.jpg")}
          onMouseOut={(e) => (e.currentTarget.src = "Images/Slider/9.jpg")}
        />
        <img
          className="sliderimg"
          src="Images/Slider/10.jpg"
          alt="10"
          onMouseOver={(e) => (e.currentTarget.src = "Images/Slider/1010.jpg")}
          onMouseOut={(e) => (e.currentTarget.src = "Images/Slider/10.jpg")}
        />
      </Carousel>
    </div>
  );
};

export default MultiSlider;
