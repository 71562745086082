import React from "react";
// import Footer from "./footer";
import Footer from "./footer2";
import Navbar from "./navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { reviewData } from "../Constants";

const ReviewDetails = () => {
  return (
    <div className="greybg">
      <Navbar review={true} />
      <div className="container pt-5">
        <h2 className="display-4 text-center">See What Our Customer Says</h2>
        <p className="text-center">
          Don’t just take our word for it; see what real Our Customers have to
          say about Antonio’s workmanship and services. The Grade A handyman
          reviews featured below are from actual home improvement projects,
          ranging from simple repairs to full-on remodels. You’ll see that you
          really can count on your local Grade A Handyman to get the job done
          right and on time.
        </p>
        <div className="row pb-2">
          {/* {reviewData.map((item, i) => ( */}
          {reviewData
            .sort(function (a, b) {
              return a.Review.length - b.Review.length;
            })
            .map((item, i) => (
              <div className="col-md-6 mt-2" key={i}>
                <div className="review-item text-center">
                  <p className="text-light pt-2">
                    <strong>- {item.Name}</strong>
                  </p>
                  <div className="my-3">
                    <FontAwesomeIcon
                      className="mx-1"
                      icon={faStar}
                      // size="2x"
                      color="yellow"
                    />
                    <FontAwesomeIcon
                      className="mx-1"
                      icon={faStar}
                      // size="2x"
                      color="yellow"
                    />
                    <FontAwesomeIcon
                      className="mx-1"
                      icon={faStar}
                      // size="2x"
                      color="yellow"
                    />
                    <FontAwesomeIcon
                      className="mx-1"
                      icon={faStar}
                      // size="2x"
                      color="yellow"
                    />
                    <FontAwesomeIcon
                      className="mx-1"
                      icon={faStar}
                      // size="2x"
                      color="yellow"
                    />
                  </div>
                  <div className="text-light review-text">
                    {/* “Joel was our Handyman. He went above and beyond the call of
                  duty. All of his work was first class, quick and professional.
                  He even worked on a window outside in a rain storm. We
                  absolutely will use ...” */}
                    {item.Review}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ReviewDetails;
