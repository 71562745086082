import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LandingPage from "./landiPage/landingPage";
import ServicesDetails from "./landiPage/servicesDetail";
import ReviewDetails from "./landiPage/reviewDetails";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route exact={true} path="/" element={<LandingPage />}></Route>
          <Route exact={true} path="/ser" element={<ServicesDetails />}></Route>
          <Route
            exact={true}
            path="/reviews"
            element={<ReviewDetails />}
          ></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
