import React from "react";
// import Footer from "./footer";
import Footer from "./footer2";
import Navbar from "./navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faReplyAll,
  faPhone,
  faEnvelopeCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { servicesDetails } from "../Constants";
import Services from "./services";

const ServicesDetails = () => {
  const location = useLocation();
  let { index } = location?.state;

  const item = servicesDetails[index];

  // console.log("Index is ", index);
  // console.log("item is ", item);

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  const Modal = () => (
    // <!-- Modal -->
    <div
      className="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header model-heading text-light">
            <h5 className="modal-title text-center" id="exampleModalLabel">
              Request Grade A Handyman Services
            </h5>
            <button
              type="button"
              className="close btn-secondary btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <h4>Make a Call</h4>
            <a href="tel:8583827226">
              <FontAwesomeIcon
                icon={faPhone}
                size="2x"
                color="#273f67"
                beat="true"
              />
              &nbsp; click to call
            </a>
            <div className="model-cell-numbers">
              <h5>(858) 382-7226</h5>
              <h5>OR</h5>
              <h5>(760) 297-0886</h5>
            </div>
            <h4>Email Us!</h4>
            <a href="mailto:a.z.handyworkservice@gmail.com">
              <FontAwesomeIcon
                icon={faEnvelopeCircleCheck}
                size="2x"
                color="#273f67"
                beat="true"
              />
              &nbsp; click to Email
            </a>
            <h5 className="pt-2">a.z.handyworkservice@gmail.com</h5>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Navbar service={true} />
      {/* <div className="service-detail-main pt-5"> */}
      <div className="greybg pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <h1 className="service-heading text-center mb-4">{item.Name}</h1>
              <p className="services-list">Services includes: </p>
              <ul className="services-list ps-5">
                {item.list.map((itemx, i) => (
                  <li key={i}>{itemx}</li>
                ))}
              </ul>

              <p className="pt-5 details-services">{item.details}</p>
              <h4 className="pt-2 text-center">
                If tasks are not listed don’t hesitate to ask
              </h4>
              <div className="text-center pt-5">
                <button
                  className="btn-lg btn-outline-dark request-button"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  <FontAwesomeIcon icon={faReplyAll} size="3x" />
                  <br />
                  Request Services
                </button>
                <Modal />
              </div>
            </div>
            <div className="col-md-5">
              <div>
                <img
                  className="serviceImage"
                  src={item.afterPic}
                  alt="1 After Work Pic"
                />
                <p className="text-center">
                  <strong>After</strong>
                </p>
                <img
                  className="serviceImage"
                  src={item.beforePic}
                  alt="2 Before Work Pic"
                />
                <p className="text-center">
                  <strong>Before</strong>
                </p>
              </div>
            </div>
          </div>
          <Services header="Other Services" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ServicesDetails;
