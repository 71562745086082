import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faGoogle, faInstagram } from "@fortawesome/free-brands-svg-icons";

const Navbar = (props) => {
  return (
    <nav className="navbar navbar-expand-lg">
      <Link to="/">
        <img className="logo" src="Images/logo.png" alt="Add logo" />
      </Link>

      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <FontAwesomeIcon icon={faBars} />
      </button>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mx-1">
          <li className="nav-item">
            <Link className="nav-a" to="/">
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              // className="nav-a active"
              className="nav-a"
              to="/"
              onClick={() => {
                const anchor = document.querySelector("#about");
                anchor.scrollIntoView({ behavior: "smooth", block: "center" });
              }}
            >
              About
            </Link>
          </li>
          <li className="nav-item">
            {/* {console.log("Service prop is ", props.service)} */}
            <Link
              // className="nav-a active"
              className={props.service ? "nav-a nav-item-focused" : "nav-a"}
              to="/"
              onClick={() => {
                if (!props.service) {
                  const anchor = document.querySelector("#services");
                  anchor.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }
              }}
            >
              Services
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className={props.review ? "nav-a nav-item-focused" : "nav-a"}
              to="/"
              onClick={() => {
                if (!props.review) {
                  const anchor = document.querySelector("#review");
                  anchor.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                  });
                }
              }}
            >
              Testimonials
            </Link>
          </li>
          <li className="nav-item">
            <Link
              className="nav-a"
              to="/"
              onClick={() => {
                const anchor = document.querySelector("#contact");
                anchor.scrollIntoView({ behavior: "smooth", block: "center" });
              }}
            >
              Contact
            </Link>
          </li>
        </ul>
        <ul className="navbar-nav" style={{ marginLeft: "auto" }}>
          <li className="nav-item pt-1">
            <Link
              className="nav-a "
              to="\angi.com/companylist/us/ca/poway/grade-a-handyman-services-reviews-8679863.htm"
              target="_blank"
            >
              <img src="Images/Angi.svg" alt="angi" className="nav-img" />
            </Link>
          </li>
          <li className="nav-item pt-1">
            <Link
              className="nav-a"
              to="\instagram.com/grade_a_handyman?igshid=YmMyMTA2M2Y="
              target="_blank"
            >
              <FontAwesomeIcon icon={faInstagram} size="lg" />
            </Link>
          </li>
          <li className="nav-item pt-1">
            <a
              className="nav-mail"
              href="mailto:a.z.handyworkservice@gmail.com"
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </a>
          </li>
          <li className="nav-item pt-1">
            <Link className="nav-a" to="\g.co/kgs/YM9ncn" target="_blank">
              <FontAwesomeIcon icon={faGoogle} />
            </Link>
          </li>
          <li className="nav-item pt-1">
            <Link
              className="nav-a "
              to="\yelp.com/biz/grade-a-handyman-services-poway"
              target="_blank"
            >
              <img src="Images/yelp.png" alt="yelp" className="nav-img-yelp" />
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
