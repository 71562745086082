import React from "react";

const ServiceArea = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <h2 className="display-4 text-center greybg curveborder">
            Area We Provide Services
          </h2>
          <div className="area-service">
            <ul className="pt-2 greybg curveborder">
              <li>
                <strong>Bonsal</strong>
              </li>
              <li>
                <strong>Cardiff by the Sea</strong>
              </li>
              <li>
                <strong>Carlsbad</strong>
              </li>
              <li>
                <strong>Del Mar</strong>
              </li>
              <li>
                <strong>Encinitas</strong>
              </li>
              <li>
                <strong>Escondido</strong>
              </li>
              <li>
                <strong>Fallbrook</strong>
              </li>
              <li>
                <strong>La Jolla</strong>
              </li>
              <li>
                <strong>Oceanside</strong>
              </li>
              <li>
                <strong>Camp Pendleton</strong>
              </li>
              <li>
                <strong>Pala</strong>
              </li>
              <li>
                <strong>Palomar Mountain</strong>
              </li>
            </ul>
            <ul className="pt-2 ms-5 greybg curveborder">
              <li>
                <strong>Pauma Valley</strong>
              </li>
              <li>
                <strong>Poway</strong>
              </li>
              <li>
                <strong>Rancho Santa Fe</strong>
              </li>
              <li>
                <strong>San Luis Rey</strong>
              </li>
              <li>
                <strong>San Marcos</strong>
              </li>
              <li>
                <strong>Solana Beach</strong>
              </li>
              <li>
                <strong>Vista</strong>
              </li>
              <li>
                <strong>Valley Center</strong>
              </li>
              <li>
                <strong>San Diego</strong>
              </li>
              <li>
                <strong>Murrieta</strong>
              </li>
              <li>
                <strong>Temecula</strong>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-8">
          <iframe
            title="Area We Serves"
            src="https://www.google.com/maps/d/u/1/embed?mid=1CiPAma0EDl2e1zd0jPUwPLueKaWlX5aB&ehbc=2E312F"
            width="100%"
            height="480"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ServiceArea;
