import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faEnvelopeCircleCheck,
  faLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const ContactUs = () => {
  return (
    <div id="contact">
      <h1 className="display-4 text-center">Contact Us</h1>

      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-6">
            <div className="row gy-4">
              <div className="col-md-6">
                <div className="contact-us-box">
                  <a href="tel:8583827226">
                    <FontAwesomeIcon
                      icon={faPhone}
                      size="2x"
                      color="#273f67"
                      beat="true"
                    />
                    &nbsp; click to call
                  </a>
                  <h3>Call Us</h3>
                  <h4>
                    <strong>(858) 382-7226</strong>
                    <br />
                    <strong>(760) 297-0886</strong>
                  </h4>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contact-us-box">
                  <a href="mailto:a.z.handyworkservice@gmail.com">
                    <FontAwesomeIcon
                      icon={faEnvelopeCircleCheck}
                      size="2x"
                      color="#273f67"
                      beat="true"
                    />
                    &nbsp; click to Email
                  </a>
                  <h3>Email Us</h3>
                  <p className="email-data py-2">
                    <strong>a.z.handyworkservice@gmail.com</strong>
                  </p>
                  <br />
                </div>
              </div>
              <div className="col-md-6">
                <div className="contact-us-box">
                  <FontAwesomeIcon
                    icon={faLocationDot}
                    size="2x"
                    color="#273f67"
                    // color="#4154f1"
                  />

                  <h3>Address</h3>
                  <p>
                    <strong>
                      14347 Old Community Rd, Street,
                      <br />
                      Poway, CA 92064,
                      <br />
                      United States
                    </strong>
                  </p>
                  <br />
                </div>
              </div>
              <div className="col-md-6">
                <div className="contact-us-box">
                  <FontAwesomeIcon
                    icon={faClock}
                    size="2x"
                    color="#273f67"
                    // color="#4154f1"
                  />
                  <h3>Open Hours</h3>
                  {/* <p>Monday - 9:00AM - 05:00PM</p> */}
                  <p>
                    Monday - Tuesday:&nbsp;
                    {/* <br /> */}
                    <strong>6:00AM - 07:00PM</strong>
                  </p>
                  <p>
                    Wednesday:&nbsp;
                    {/* <br /> */}
                    <strong>7:00AM - 07:00PM</strong>
                  </p>
                  <p>
                    Thursday - Friday:&nbsp;
                    {/* <br /> */}
                    <strong>6:00AM - 07:00PM</strong>
                  </p>
                  <p>
                    Saturday:&nbsp;
                    {/* <br /> */}
                    <strong>8:00AM - 05:00PM</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 contact-data text-center">
            <h1>GET IN TOUCH</h1>
            <br />
            <h4>
              Hey! We are looking forward <br /> to start a project with you!
            </h4>
            <br />
            <p>
              Do you know someone who would benefit from our services? Now you
              can make some extra cash just by spreading the word of our
              services. When you refer a friend to us, you’ll receive a{" "}
              <i>10%</i> commission payout. <strong>CONTACT ME!</strong>
            </p>
            <br />

            <br />
            <div>
              <Link
                to="\instagram.com/grade_a_handyman?igshid=YmMyMTA2M2Y="
                target="_blank"
              >
                <img className="nav-image" src="Images/insta.png" alt="1" />
              </Link>
              &nbsp;
              <Link
                to="\g.page/r/CXnGYeZLKUHOEAg/review"
                target="_blank"
              >
                <img className="nav-image" src="Images/gr.png" alt="1" />
              </Link>
              &nbsp;
              <Link
                to="\yelp.com/writeareview/biz/Cih62sZQwWOL08g-hXBjQw?return_url=%2Fbiz%2FCih62sZQwWOL08g-hXBjQw&review_origin=biz-details-war-button"
                target="_blank"
              >
                <img className="nav-image" src="Images/yr.png" alt="1" />
              </Link>
              &nbsp;
              <img className="nav-image" src="Images/bcs.png" alt="1" />
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default ContactUs;
